/* eslint-disable react/no-array-index-key */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {truncate} from 'lodash';

import ProfileCard from './ProfileCard';
import {useAppContext} from '../hooks';

export class ScrollingTestimonials extends Component {
  animationID = null;

  startTs = null;

  width = null;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.start();
    const el = document.querySelector('.ScrollingTestimonials__inner');
    this.width = el.scrollWidth;
  }

  start = () => {
    this.animationID = window.requestAnimationFrame(this.step);
  };

  pause = () => {
    window.cancelAnimationFrame(this.animationID);
    this.startTs = null;
  };

  step = (timestamp) => {
    if (!this.startTs) this.startTs = timestamp;

    const progress = timestamp - this.startTs;
    let dx = -1 * ((progress / 40) % (this.width / 2));

    if (this.props.reverse) {
      dx = -1 * dx - this.width / 2;
    }

    this.setState({transform: `translateX(${dx}px)`});
    this.animationID = window.requestAnimationFrame(this.step);
  };

  render() {
    // The parent container is fixed and the testimonials scroll through it
    const items = [].concat(this.props.items, this.props.items);
    return (
      <div className="ScrollingTestimonials">
        <div className="ScrollingTestimonials__inner flex py-4" style={{transform: this.state.transform}}>
          {items.map((item, i) => (
            <ProfileCard
              title={item.name}
              subtitle={item.title}
              image={`${item.image}?w=128`}
              description={truncate(item.text, {length: 150})}
              key={`${item.name}_${i}`}
              className="mx-4 max-w-sm w-full flex-shrink-0"
            />
          ))}
        </div>
      </div>
    );
  }
}

export const TestimonialSection = ({dark, showHeader = true}) => {
  let {testimonials} = useAppContext();
  if (!testimonials) return null;

  // Only show featured
  testimonials = testimonials.filter((t) => t.featured);

  return (
    <div className={cx('w-full overflow-hidden p-8', {'bg-gray-900': dark})}>
      {showHeader && (
        <>
          <h3 className={cx('text-center text-base font-bold uppercase tracking-wider', {
            'text-gray-400': dark,
            'text-indigo': !dark,
          })}
          >
            Our community
          </h3>
          <h2 className={cx('mt-2 mb-6 text-center text-2xl md:text-4xl font-black', {
            'text-white': dark,
            'text-black': !dark,
          })}
          >
            Join over 600,000 people using Exponent
          </h2>
        </>
      )}
      <ScrollingTestimonials items={testimonials.slice(0, testimonials.length / 2)} />
      <ScrollingTestimonials items={testimonials.slice(testimonials.length / 2)} reverse />
    </div>
  );
};

ScrollingTestimonials.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  reverse: PropTypes.bool,
};

ScrollingTestimonials.defaultProps = {
  reverse: false,
};
